export enum StudyProgramPeriodType {
  AcademicYear = 'ayp',
  Semester = 'sem',
  CalendarYear = 'cyp',
  ThreeSemester = 'sem3',
  Term = 'term',
  Trimester = 'tri',
}

export const StudyProgramPeriodTypeNames: { [key in StudyProgramPeriodType]: string } = {
  [StudyProgramPeriodType.CalendarYear]: 'Calendar year',
  [StudyProgramPeriodType.AcademicYear]: 'Academic year',
  [StudyProgramPeriodType.Semester]: 'Semester',
  [StudyProgramPeriodType.ThreeSemester]: 'Three semester',
  [StudyProgramPeriodType.Term]: 'Term',
  [StudyProgramPeriodType.Trimester]: 'Trimester',
};
