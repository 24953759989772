import './App.scss';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Layout } from 'app/marketing/Layout';
import { theme } from 'app/theme';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Auth0ProviderWithNavigate } from './auth/Auth0ProviderWithNavigate';
import { CallbackLanding } from './auth/CallbackLanding';
import { Login } from './auth/Login';
import { RequireAuthForRoute } from './auth/RequireAuthForRoute';
import { RequireAuthUnlessIframedForRoute } from './auth/RequireAuthUnlessIframedForRoute';
import { Edit } from './marketing/profiles/Edit';
import { Preview } from './marketing/profiles/Preview';
import { ProfileLoader } from './marketing/profiles/ProfileLoader';
import { View } from './marketing/profiles/View';
import { ProviderList } from './marketing/ProviderList';
import { PageNotFound } from './PageNotFound';
import { PageTitleProvider } from './PageTitleContext';
import { VersionCheckerModal } from './VersionCheckerModal';

export const App = () => {
  return (
    <SnackbarProvider maxSnack={3}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <PageTitleProvider>
              <Auth0ProviderWithNavigate>
                <Routes>
                  <Route path="login" element={<Login />} />
                  <Route path="callback" element={<CallbackLanding />} />
                  <Route path="marketing" element={<Layout />}>
                    <Route
                      path=""
                      element={
                        <RequireAuthUnlessIframedForRoute>
                          <ProviderList />
                        </RequireAuthUnlessIframedForRoute>
                      }
                    />
                    <Route path="profiles/:profileSlug" element={<ProfileLoader />}>
                      <Route path="edit">
                        <Route
                          path=":editTab"
                          element={
                            <RequireAuthForRoute>
                              <Edit />
                            </RequireAuthForRoute>
                          }
                        />
                        <Route path="" element={<Navigate replace to="info" />} />
                      </Route>
                      <Route
                        path="preview"
                        element={
                          <RequireAuthForRoute>
                            <Preview />
                          </RequireAuthForRoute>
                        }
                      />
                      <Route
                        path="view"
                        element={
                          <RequireAuthUnlessIframedForRoute>
                            <View />
                          </RequireAuthUnlessIframedForRoute>
                        }
                      />
                      <Route path="shared-view/p/:region/u/:userId/vp/:viewPricing" element={<View />} />
                    </Route>
                    <Route path="*" element={<Navigate replace to="/marketing" />} />
                  </Route>
                  <Route path="" element={<Navigate replace to="/marketing" />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
                <VersionCheckerModal />
              </Auth0ProviderWithNavigate>
            </PageTitleProvider>
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
};
